import React, { useState } from "react";
import { useSelector } from "react-redux";
import plantBackground from "../../images/plant-background.jpg";
import PortraitOne from "../../images/portrait-1.jpg";
import "../SectionOne/SectionOne.css";
import { Row, Col } from "react-bootstrap";

function TemplateFunction(props) {
  const store = useSelector((store) => store);

  return (
    <div className="background">
      <Row className="sectionOneRow">
        <Col className="imageCol" xs="12" md="6">
          <img
            alt="plant background"
            className="plantBackground"
            src={plantBackground}
          />
          <img alt="portrait" className="PortraitOne" src={PortraitOne} />
        </Col>
        <Col className="textCol" md="6">
          <div>
            <div className="heyThereDiv">
              <hr className="heyThereHr"></hr>
              <p className="heyThere">hey there</p>
            </div>
            <p className="sectionOneParagraph">
              I AM A FULL STACK SOFTWARE DEVELOPER, UX/UI DESIGNER &
              PHOTOGRAPHER
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TemplateFunction;
