import React, { useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../PreviousWork/PreviousWork.css";
import pbs from "../../images/pbs.png";
import lrt from "../../images/lrt.jpg";

function PreviousWork(props) {
  const store = useSelector((store) => store);
  const [heading, setHeading] = useState("Functional Component");

  return (
    <div className="previousWorkContainerDiv" id="prev-work">
      <Container>
        <div className="previousWorkHrandTextDiv">
          <hr className="previousWorkHr" />
          <h2 className="previousWorkh2">Previous Work</h2>
        </div>

        <div className="previousWorkDivs">
          <div className="previousWorkDiv">
            <div>
              <center>
                <img src={pbs} className="previousWorkImage" />
              </center>
            </div>
            <div className="card-body">
              <center>
                <p className="paragraph">
                  Plant-Based Social is a social media recipe app where users
                  can view, like, save, comment on, and share plant-based
                  recipes with their family and friends.
                </p>
              </center>
              <center>
                <a
                  href="https://www.youtube.com/watch?v=13dCYway2E0"
                  target="_blank"
                  className="seeMoreButton"
                >
                  SEE MORE
                </a>
              </center>
            </div>
          </div>

          <div className="previousWorkDiv">
            <div>
              <center>
                <img src={lrt} className="previousWorkImage" />
              </center>
            </div>
            <div className="card-body">
              <center>
                <p className="paragraph">
                  Living Room Tutors is an application and database that
                  suggests matches between tutors and tutees based on the data
                  submitted, sends confirmation emails and generates template
                  emails for successful matches.
                </p>
              </center>
              <center>
                <a
                  href="https://www.youtube.com/watch?v=vS6QSfql8NY"
                  target="_blank"
                  className="seeMoreButton"
                >
                  SEE MORE
                </a>
              </center>
            </div>
          </div>

          {/* <div className="previousWorkDiv">
            <center>
              <div>
                <img src={lrt} className="previousWorkImage" />
                <div className="card-body">
                  <p>
                    Living Room Tutors is an application and database that
                    suggests matches between tutros and tutees based on the data
                    submitted, sends confirmation emails and generates template
                    emails for successful matches.
                  </p>
                </div>
                <a
                  href="https://www.youtube.com/watch?v=vS6QSfql8NY"
                  target="_blank"
                  className="seeMoreButton"
                >
                  SEE MORE
                </a>
              </div>
            </center>
          </div> */}
        </div>
      </Container>
    </div>
  );
}

export default PreviousWork;
