import React from "react";
import AboutMePhoto from "../../images/portrait-two.jpg";
import { Container, Col, Row } from "react-bootstrap";

import "./AboutPage.css";

function AboutPage({ aboutRef }) {
  return (
    <div className="about-section" ref={aboutRef}>
      <Container className="about-container">
        <p className="large-text-cursive">oh hey there!</p>
        <p className="large-text">My name is Hannah</p>
        {/* <hr className="about-hr" /> */}
        <div>
          <img src={AboutMePhoto} className="about-me-photo" />
          <p className="about-me-text">
            {" "}
            I am a software developer, UX Designer and photographer. I’m a
            curious self-starter, with a contagious ambition that drives those
            around me to be their best as well. <br />
            <br />
            As a professional photographer with a bachelor’s degree in marketing
            and communications, I’ve always been a driven leader and creative
            spirit. When I began to explore the fundamentals of UI and UX
            design, I realized I wanted to know what went on behind the scenes
            to actualize the sleek interfaces I designed. The empath in me
            understood the importance of knowing what it is I’m asking
            developers to create—and what better way to put myself in their
            shoes than by becoming one myself? That’s when I set on a journey to
            learn the full-stack of web-development tools. Since then, I’ve
            established myself as a strong developer with a knack for creating
            applications that are not only usable, but useful and beautiful.
            Now, I not only speak 3 languages, but can code in 5 (and counting)
            as well.
            <br />
            <br />
            When I’m not thinking about what skill I want to develop next, I
            spend my time trying new vegan recipes (ask me about my vegan
            sushi), travelling to warmer places, foraging for mushrooms, and
            practicing meditation. My creativity is fueled by these moments of
            respite, as well as a good matcha latte and earth tones. In UI and
            UX design and development, I have found a place where my analytical
            and creative sides can live in harmony to make a true impact on the
            world, and I get to have fun while doing it.
          </p>
        </div>
      </Container>
    </div>
  );
}

export default AboutPage;
