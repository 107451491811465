import React from "react";
import LogOutButton from "../LogOutButton/LogOutButton";
import "./Nav.css";
import { useSelector } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";

function NavBar() {
  const user = useSelector((store) => store.user);

  return (
    <div>
      <center>
        <div className="NavTop">
          {" "}
          <span className="titleSpan">WELCOME TO MY PORTFOLIO!</span>{" "}
        </div>
      </center>

      <Navbar className="nav" expand="md">
        <Navbar.Brand className="nav-title" href="#home">
          Hannah Fligel
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {/* <Nav.Link className="navLink" href="#/about">
              About
            </Nav.Link>
            <Nav.Link className="navLink" href="#prev-work">
              Projects
            </Nav.Link> */}
            <Nav.Link
              className="navLink"
              href="https://github.com/hannahfligel"
              target="_blank"
            >
              github
            </Nav.Link>
            <Nav.Link
              className="navLink"
              href="https://hannahfligelphoto.myportfolio.com/"
              target="_blank"
            >
              Photography
            </Nav.Link>
            <Nav.Link
              className="navLink"
              href="mailto:hannahfligelphoto@gmail.com"
              target="_blank"
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NavBar;
