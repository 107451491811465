import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import "./LandingPage.css";
import SectionOne from "../SectionOne/SectionOne";
import SectionTwo from "../SectionTwo/SectionTwo";
import AboutPage from "../AboutPage/AboutPage";
import PreviousWork from "../PreviousWork/PreviousWork";

// CUSTOM COMPONENTS
import RegisterForm from "../RegisterForm/RegisterForm";

function LandingPage() {
  const [heading, setHeading] = useState("Welcome");
  const history = useHistory();

  const onLogin = (event) => {
    history.push("/login");
  };

  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <AboutPage />
      <PreviousWork />
    </div>
  );
}

export default LandingPage;
