import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../SectionTwo/SectionTwo.css";
import { Row, Col, Container } from "react-bootstrap";
import iconOne from "../../images/design-thinking.png";
import iconTwo from "../../images/coding.png";
import iconThree from "../../images/skill.png";

function SectionTwo(props) {
  const store = useSelector((store) => store);

  return (
    <div className="sectionTwoDiv">
      <Container>
        <center>
          <h2 className="skillsH2">SKILLS</h2>
          <hr className="skillseHr"></hr>
          <Row className="skillsDiv">
            <Col xs="12" md="3" className="skillsItem">
              <img
                className="skillsIcon"
                alt="design-icon icon"
                src={iconOne}
              />
              <h3 className="skillsHeader">UX/UI Methods & Technologies</h3>
              <p className="skillsParagraph">
                Research Methods, Adobe Suite (Adobe XD, Illustrator, InDesign,
                Photoshop, Lightroom, Premiere, Spark), Axure, Figma, Canva,
                Color Theory
              </p>
            </Col>
            <Col xs="12" md="3" className="skillsItem">
              <img className="skillsIcon" src={iconTwo} />
              <h3 className="skillsHeader">Development Technologies</h3>
              <p className="skillsParagraph">
                React, Redux, Axios, Javascript, Node, Express, PostgreSQL,
                Postico, Bootstrap, HTML, CSS
              </p>
            </Col>
            <Col xs="12" md="3" className="skillsItem">
              <img className="skillsIcon" src={iconThree} />
              <h3 className="skillsHeader">Soft Skills</h3>
              <p className="skillsParagraph">
                Professional Communication, Management, Customer Service, Team
                Leadership, Marketing Strategies
              </p>
            </Col>
          </Row>
        </center>
      </Container>
    </div>
  );
}

export default SectionTwo;
