import React from "react";
import "./Footer.css";
import { Container, Col, Row, Button } from "react-bootstrap";
import Email from "../../images/email.png";
import github from "../../images/github.png";
import insta from "../../images/insta.png";
import linkdin from "../../images/linkdin.png";
import arrow from "../../images/up-arrow.png";

function Footer() {
  return (
    <div className="footer-all">
      <div className="footer-top">
        <Container>
          <Row>
            <Col className="footer-column" xs="4">
              <p className="HannahFligel">HANNAH FLIGEL</p>
              <div>
                <a href="mailto:hannahfligelphoto@gmail.com" className="email">
                  <img className="Icon" src={Email} />
                  <p>fligelhannah@gmail.com</p>
                </a>
              </div>
            </Col>

            <Col className="footer-column-centered" xs="4">
              {/* <img className="topIcon" src={arrow} />
            BACK TO TOP */}
            </Col>

            <Col className="footer-column-end" xs="4">
              <a
                href="https://github.com/hannahfligel?tab=repositories"
                target="_blank"
              >
                <img className="Icon" src={github} />
              </a>
              <a href="https://www.instagram.com/hannahfphoto/" target="_blank">
                <img className="Icon" src={insta} />
              </a>
              <a
                href="https://www.linkedin.com/in/hannah-fligel-480a0599/"
                target="_blank"
              >
                <img className="Icon" src={linkdin} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer">
        &copy; Hannah Fligel 2022. All Rights Reserved. | Photos by{" "}
        <a
          target="_blank"
          href="http://marinaborovphoto.com/"
          className="marina"
        >
          {" "}
          Marina Borovikova
        </a>
      </footer>
    </div>
  );
}

export default Footer;
